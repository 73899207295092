import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import RestartAltIcon from '@mui/icons-material/RestartAlt';

const Reset = ({ setActiveFilter }) => {
	const handleReset = () => {
		setActiveFilter(null);
		document.querySelectorAll('.filter').forEach((element) => {
			element.style.filter = '';
		});
		document
			.querySelectorAll('section, .filter,  header')
			.forEach((element) => {
				element.style.backgroundColor = 'white';
			});
		document.querySelector('body').style.background = '';
		document.querySelectorAll('#root *').forEach((element) => {
			element.style.fontFamily = '';
		});
		document
			.querySelectorAll(
				'.MuiTypography-root, .MuiTypography-root *, .accessiblility-links, .MuiIconButton-root'
			)
			.forEach((element) => {
				element.style.color = '';
			});
		document
			.querySelectorAll('a, button, .MuiAccordionSummary-content, .accessiblility-links')
			.forEach((link) => {
				link.style.textDecoration = 'none';
			});
	};
	return (
		<ListItem
			className='accessiblility-links'
			tabIndex={1}
			sx={{
				padding: '2px 18px',
				cursor: 'pointer'
			}}
			onClick={handleReset}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					handleReset();
				}
			}}
		>
			<RestartAltIcon sx={{ fontSize: '14px', verticalAlign: 'middle', marginRight: '5px' }} />
			<ListItemText primary='Reset' primaryTypographyProps={{ sx: { fontSize: '14px' } }} />
		</ListItem>
	);
};

Reset.propTypes = {
	setActiveFilter: PropTypes.func
};

export default Reset;
